import React from 'react';
import './App.css';
import BasePage from './base/BasePage';
import Login from './login/Login';

const App: React.FunctionComponent <{}>= ()  =>{
  document.title = "Hermes";
  return (
    <div className="App">
      <BasePage>
        <Login />
      </BasePage>
       {/* The core Firebase JS SDK is always required and must be listed first */}
      <script src="/__/firebase/7.14.1/firebase-app.js"></script>

      {/* <!-- TODO: Add SDKs for Firebase products that you want to use
          https://firebase.google.com/docs/web/setup#available-libraries --> */}
      <script src="/__/firebase/7.14.1/firebase-analytics.js"></script>

      {/* <!-- Initialize Firebase --> */}
      <script src="/__/firebase/init.js"></script>
    </div>
  );
}

export default App;
