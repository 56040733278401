import { AppBar, CssBaseline, Grid, IconButton, makeStyles, Paper, Toolbar, Typography } from "@material-ui/core";
import { BubbleChartOutlined } from "@material-ui/icons";
import image from '../assets/image.jpg';

const useStyles = makeStyles((theme) => ({
    main: {
        height: '100vh',
    },
    header: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    image: {
      backgroundImage: `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'left',
    },
    paper: {
      margin: theme.spacing(16, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    footer: {
      top: 'auto',
      bottom: 0
    },
    footerCompany: {
      position: 'relative',
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    }
  }));
  
  export default function BasePage(props) {
    const classes = useStyles();
  
    return (
      <Grid container component="main" className={classes.main}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.header}>
              <AppBar position="fixed">
                <Toolbar>
                  <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <BubbleChartOutlined />
                  </IconButton>
                  <Typography variant="h5">
                    Hermes
                  </Typography>
                </Toolbar>
              </AppBar>    
            </div>
            <div className={classes.paper}>
                  {props.children}
            </div>
            <AppBar position="fixed" color="default" className={classes.footer}>
              <Typography>
                Powered by Hermes
              </Typography>
            </AppBar>      
          </Grid>
      </Grid>
    );
  }
