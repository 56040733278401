import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import './Login.css'

function Login() {
    return (
        <div id="login">
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div >
                <Typography component="h1" variant="h5">
                Sign in
                </Typography>
                <form >
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    />
                <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me (&#x1d195;though I have to say goodbye&#x1d195;)"
                    disabled
                    />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled
                    >
                    Sign In
                </Button>
                </form>
                <p className="Warning">
                    You can't sign in right now.<br />Please try again later!
                </p>
            </div>
            </Container>
        </div>
    );
}

export default Login;